<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row class="justify-content-end">
        <!-- Search -->
        <!-- <b-col
          cols="6"
          md="9"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('globalActions.findCode')"
            />
          </div>
          <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                @click="SearchData(searchQuery)"
              > Cari
          </b-button>
          </div>
        </b-col> -->

        <!-- Entry -->
        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
        >
          <b-button
            variant="primary"
            :to="{ name: 'apps-transactions-cash-advance-add'}"
          >
            <feather-icon icon="PlusIcon" /> {{ $t('apps.transactionsCashAdvance.actions.create') }}
          </b-button>
        </b-col>
      </b-row>
    </div>

    <b-table
      id="refListTable"
      ref="refListTable"
      no-provider-paging
      no-provider-filtering
      :fields="tableColumns"
      :items="itemLists"
      responsive
      primary-key="id"
      show-empty
      :busy="isBusy"
      :empty-text="$t('globalSingular.noMatching')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #table-busy>
        <div class="text-center text-primary my-2 d-flex flex-column justify-content-center">
          <div class="text mb-1">
            <b-spinner class="align-middle"/>
          </div>
          <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
        </div>
      </template>

      <template #head()="{ field }">
        <table-search-column
          :field="field"
          :fields="tableColumns"
          :focused-field="focusedSearchField"
          @set-focused-field="(value) => focusedSearchField = value"
          @search-data="(params) => searchData(params)"
        />
      </template>

      <template #cell(code)="data">
        <b-link
          :to="{ name: 'apps-transactions-cash-advance-detail', params: { id: data.item.id }}"
          class="font-weight-bold"
        >
          {{ data.item.code }}
        </b-link>
      </template>

      <template #cell(date)="data">
        <span class="font-weight-bold d-block text-nowrap">
          {{ data.item.date }}
        </span>
      </template>

      <template #cell(purpose_date)="data">
        <span class="font-weight-bold d-block text-nowrap">
          {{ data.item.purpose_date }}
        </span>
      </template>

      <template #cell(employee)="data">
        <span class="font-weight-bold d-block text-nowrap">
          {{ data.item.employee.code }} - {{ data.item.employee.pic_name }}
        </span>
      </template>

      <template #cell(user_create)="data">
        <span class="font-weight-bold d-block text-nowrap">
          {{ data.item.userCreate.user_name }}
        </span>
      </template>

      <template #cell(request_amount)="data">
        <span class="font-weight-bold d-block text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <template #cell(audit)="{ item }">
        <span class="font-weight-bolder d-block text-nowrap">
        {{ item.updated_process }}  By {{ item.userCreate.user_name }}
        </span>
        <small class="font-italic">{{ new Date(item.updated_at).toDateString() }}</small>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalList"
            :per-page="perPage"
            first-number
            last-number
            class="mt-1 mb-0 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="refInvoiceListTable"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BLink,
  BPagination,
  BSpinner,
  BButton
} from 'bootstrap-vue'
import TableSearchColumn from "@/components/TableSearchColumn.vue"

import { formatCurrency } from '@/utils/formatter'
import useLocalization from '@/comp-functions/utils/useLocalization'
import useListTablePage from '@/comp-functions/useListTablePage'
import { ref } from '@vue/composition-api'


export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BLink,
    BPagination,
    BSpinner,
    BButton,
    TableSearchColumn
  },


  setup () {
    // Table Handlers
    const { $t } = useLocalization()

    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      focusedSearchField,
      fetchLists,
      changePerPage,
      searchData
    } = useListTablePage({ url: 'transaction/cash-advance?' })

    const tableColumns = ref([
      {
        key: 'code',
        label: $t('globalSingular.code'),
        sortable: true,
        search: { type: 'text', value: '' }
      },
      {
        key: 'cashAdvanceRequest.code',
        label: $t('globalSingular.requestNo'),
        sortable: true,
        search: {
          type: 'text',
          value: '',
          key: 'cash_advance_request_code'
        }
      },
      {
        key: 'date',
        label: $t('globalSingular.date'),
        search: { type: 'date', value: '' }
      },
      {
        key: 'getOffice.label',
        label: $t('globalSingular.branch'),
        search: {
          type: 'select',
          value: '',
          key: 'office_id',
          url: 'value/getBranch'
        }
      },
      {
        key: 'employee',
        label: $t('globalSingular.employee'),
        search: {
          type: 'select',
          value: '',
          key: 'employee_id',
          url: 'value/contact/employee',
        }
      },
      {
        key: 'amount',
        label: $t('globalSingular.amount'),
        formatter: value => value ? formatCurrency(value) : '-',
        search: { type: 'number', value: 0 }
      },
      {
        key: 'realisation_amount',
        label: $t('globalSingular.realizationAmount'),
        formatter: value => value ? formatCurrency(value) : '-',
        search: {
          type: 'text',
          value: '',
          min_length: 1,
        }
      },
      {
        key: 'refund_amount',
        label: $t('globalSingular.refundAmount'),
        formatter: value => value ? formatCurrency(value) : '-',
        search: {
          type: 'text',
          value: '',
          min_length: 1,
        }
      },
      {
        key: 'outstanding_amount',
        label: $t('globalSingular.outstandingAmount'),
        formatter: (_, __, item) => item.out ? formatCurrency(item.out) : '-',
        search: {
          type: 'text',
          value: '',
          min_length: 1,
        }
      },
      {
        key: 'user_create',
        label: $t('globalSingular.createdBy'),
        search: { type: 'text', value: '' }
      },
      {
        key: 'audit',
        label: $t('globalSingular.audit'),
        search: { type: 'text', value: '' }
      }
    ])

    return {
      tableColumns,
      formatCurrency,
      changePerPage,
      searchData,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists,
      focusedSearchField
    }
  }
}
</script>
